import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Converter from './components/Converter';

import logo from './logo.svg';
import './App.css';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function App() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '50px',
      }}
    >
      {' '}
      <Converter />
      <div
        style={{
          marginTop: '50px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p style={{ margin: '0px' }}>
          {' '}
          Made by{' '}
          <a href="https://twitter.com/tansawit" target="_blank" style={{ textDecoration: 'none' }}>
            tansawit
          </a>
        </p>
        <p>
          Price data powered by{' '}
          <a href="https://bandprotocol.com" target="_blank" style={{ textDecoration: 'none' }}>
            Band Protocol
          </a>
          's{' '}
          {/*  <a
            href="https://data.bandprotocol.com"
            target="_blank"
            style={{ textDecoration: 'none' }}
          > */}
          {/* Standard Dataset */} Oracle
          {/* </a> */}
        </p>
      </div>
    </div>
  );
}

export default App;
