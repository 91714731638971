import React from 'react';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components';

// export const BaseLoader = (props) => (
//   <ContentLoader
//     width="120"
//     speed={2}
//     backgroundColor="#eaeaea"
//     foregroundColor="#ccc"
//     {...props}
//   />
// )

const BaseLoader = styled(ContentLoader).attrs((props) => ({
  width: '120',
  speed: 2,
  backgroundColor: '#eaeaea',
  foregroundColor: '#ccc',
  ...props,
}))``;

export const TextLoader = (props) => (
  <BaseLoader>
    <rect x="0" y="0" rx="2" ry="2" height="20" width="120" />
  </BaseLoader>
);

export const RectLoader = ({ x = 0, y = 0, r = 2, height, width }) => (
  <BaseLoader height={height} width={width}>
    <rect x={x} y={y} rx={r} ry={r} height={height} width={width} />
  </BaseLoader>
);
