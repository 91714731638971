const BandChain = require('@bandprotocol/bandchain.js');
const axios = require('axios').default;
const { BANDCHAIN_ENDPOINT, ASK_COUNT, MIN_COUNT } = require('./constants.js');

async function getSupportedSymbols() {
  const endpoint = `${BANDCHAIN_ENDPOINT}/oracle/price_symbols?ask_count=${ASK_COUNT}&min_count=${MIN_COUNT}`;
  const res = await axios.get(endpoint);
  return res.data.result;
}

async function getPrice(symbol) {
  const bandchain = new BandChain(BANDCHAIN_ENDPOINT);
  const rates = await bandchain.getReferenceData([`${symbol}/USD`]);
  return { rate: rates[0].rate, lastUpdated: rates[0].updated.base };
}

export { getSupportedSymbols, getPrice };
