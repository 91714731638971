import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { getSupportedSymbols, getPrice } from '../utils/band.js';
import PriceChart from '../components/PriceChart';
import { TheGraphProvider } from '../data/thegraph';

async function fetchSupportedSymbols(setSupportedSymbols) {
  const symbols = await getSupportedSymbols();
  symbols.push('USD');
  setSupportedSymbols(symbols);
}

async function fetchPrices(
  baseSymbol,
  setBasePrice,
  setBaseAmount,
  quoteSymbol,
  setQuotePrice,
  setQuoteAmount
) {
  const bp = await getPrice(baseSymbol);
  const qp = await getPrice(quoteSymbol);

  setBasePrice(bp.rate.toFixed(8));
  setQuotePrice(qp.rate.toFixed(8));

  setBaseAmount(1.0);
  setQuoteAmount(bp.rate);
}

const Selector = ({
  type,
  symbols,
  currentSymbol,
  handleChange,
  readOnly,
  baseAmount,
  quoteAmount,
  basePrice,
  quotePrice,
  setBaseAmount,
}) => {
  return (
    <div style={{ display: 'flex' }}>
      <Autocomplete
        style={{ width: 200 }}
        width="10px"
        id="tokens"
        options={symbols}
        autoHighlight
        defaultValue={currentSymbol}
        getOptionLabel={(option) => option}
        onChange={handleChange}
        renderOption={(option) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={`images/tokens/${option}.png`}
              width="15px"
              style={{ marginRight: '10px', marginTop: 'auto', marginBottom: 'auto' }}
            />
            {option}
          </div>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Choose a token"
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
            }}
          />
        )}
      />
      <TextField
        style={{ width: '250px' }}
        id="outlined-number"
        label="Amount"
        type="number"
        InputLabelProps={{
          shrink: true,
          readOnly: true,
        }}
        InputProps={{
          readOnly: readOnly,
        }}
        variant="outlined"
        defaultValue={quoteAmount}
        value={type == 'base' ? baseAmount : (basePrice * baseAmount) / quotePrice}
        onChange={(e) => {
          setBaseAmount(e.target.value);
        }}
      />
    </div>
  );
};

export default () => {
  const [supportedSymbols, setSupportedSymbols] = useState([]);
  const [baseSymbol, setBaseSymbol] = useState('BTC');
  const [quoteSymbol, setQuoteSymbol] = useState('USD');
  const [baseAmount, setBaseAmount] = useState(1.0);
  const [quoteAmount, setQuoteAmount] = useState(1.0);
  const [basePrice, setBasePrice] = useState(0.0);
  const [quotePrice, setQuotePrice] = useState(0.0);
  const [duration, setDuration] = useState('day');

  async function handleBaseChange(event, newValue) {
    const bp = await getPrice(newValue);
    setBaseSymbol(newValue);
    setBasePrice(bp.rate);
  }
  async function handleQuotechange(event, newValue) {
    const qp = await getPrice(newValue);
    setQuoteSymbol(newValue);
    setQuotePrice(qp.rate);
  }

  function handleDurationChange(event) {
    setDuration(event.target.value);
  }

  useEffect(() => {
    fetchSupportedSymbols(setSupportedSymbols);
    fetchPrices(
      baseSymbol,
      setBasePrice,
      setBaseAmount,
      quoteSymbol,
      setQuotePrice,
      setQuoteAmount
    );
  }, []);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '75vh',
        width: '100vw',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        className="App"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '25px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginRight: '10px',
            marginLeft: '10px',
            alignItems: 'start',
          }}
        >
          <Selector
            type={'base'}
            label={'From'}
            symbols={supportedSymbols}
            currentSymbol={baseSymbol}
            handleChange={handleBaseChange}
            readOnly={false}
            baseAmount={baseAmount}
            quoteAmount={quoteAmount}
            basePrice={basePrice}
            quotePrice={quotePrice}
            setBaseAmount={setBaseAmount}
          />
          <p style={{ fontSize: '12px' }}>
            1 {baseSymbol} = {basePrice} USD
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginRight: '10px',
            marginLeft: '10px',
            alignItems: 'start',
          }}
        >
          <Selector
            type={'quote'}
            label={'To'}
            symbols={supportedSymbols}
            currentSymbol={quoteSymbol}
            handleChange={handleQuotechange}
            readOnly={true}
            baseAmount={baseAmount}
            quoteAmount={quoteAmount}
            basePrice={basePrice}
            quotePrice={quotePrice}
            setBaseAmount={setBaseAmount}
          />
          <p style={{ fontSize: '12px' }}>
            1 {quoteSymbol} = {quotePrice} USD
          </p>
        </div>
      </div>
      <div style={{ width: '80vw' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <p>
            <b>{baseSymbol}</b> {duration == 'day' ? '24-Hour ' : '7-Day '} Price History
          </p>
          <FormControl component="fieldset">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: '20px' }}>
                <FormLabel component="legend">Timeframe</FormLabel>
              </div>
              <RadioGroup
                style={{ display: 'flex', flexDirection: 'row' }}
                aria-label="duration"
                name="duration1"
                value={duration}
                onChange={handleDurationChange}
              >
                <FormControlLabel value="day" control={<Radio />} label="Day" />
                <FormControlLabel value="week" control={<Radio />} label="Week" />
              </RadioGroup>
            </div>
          </FormControl>
        </div>
        <div style={{ marginTop: '10px' }}>
          <TheGraphProvider network="kovan">
            <PriceChart symbol={baseSymbol} duration={duration} />
          </TheGraphProvider>
        </div>
      </div>
    </div>
  );
};
