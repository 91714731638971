import React from 'react';
import memCache from 'graphql-hooks-memcache';

import { GraphQLClient, ClientContext, useQuery } from 'graphql-hooks';

export const theGraphClients = {
  kovan: new GraphQLClient({
    url: 'https://api.thegraph.com/subgraphs/name/sorawit/band-standard-reference-kovan',
    cache: memCache(),
  }),
};

export const TheGraphProvider = ({ network, children }) => (
  <ClientContext.Provider value={theGraphClients[network]}>{children}</ClientContext.Provider>
);

export const useTheGraphPrice = (symbol, duration) => {
  let resolveTime = 0;
  if (duration == 'hour') {
    resolveTime = Math.floor((Date.now() / 1000 - 60 * 60) / 60) * 60;
  } else if (duration == 'day') {
    resolveTime = Math.floor((Date.now() / 1000 - 24 * 60 * 60) / 60) * 60;
  } else if (duration == 'week') {
    resolveTime = Math.floor((Date.now() / 1000 - 7 * 24 * 60 * 60) / 60) * 60;
  }
  let { loading, error, data } = useQuery(`query useTheGraphPrice {
    refRates(
      where: { 
        symbol: "${symbol}",
        resolveTime_gt: ${resolveTime}
      },
      orderBy: resolveTime,
      orderDirection: asc,
    ) {
      id
      symbol
      rate
      resolveTime
      requestId
    }
  }`);

  if (!loading && !error) {
    data = data.refRates.map(({ rate, resolveTime, requestId }) => ({
      time: Number(resolveTime) * 1000,
      price: Number(rate) / 1e9,
      requestId,
    }));
  }

  return { loading, error, data };
};
