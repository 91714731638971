import React from 'react';
import { RectLoader } from './Loader';
import { useTheGraphPrice } from '../data/thegraph';
import {
  ResponsiveContainer,
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Line,
  Tooltip,
} from 'recharts';
import dayjs from 'dayjs';

export const formatPrice = (price) =>
  price
    .toLocaleString('en-US', {
      minimumFractionDigits: 3,
      minimumSignificantDigits: 4,
      maximumSignificantDigits: 8,
    })
    .slice(0, 10);

export default ({ symbol, duration }) => {
  const { loading, error, data } = useTheGraphPrice(symbol, duration);

  const tempData = [{ name: 'Page A', uv: 400, pv: 2400, amt: 2400 }];

  const renderLineChart = () => (
    <LineChart width={600} height={300} data={tempData}>
      <Line type="monotone" dataKey="uv" stroke="#8884d8" />
      <CartesianGrid stroke="#ccc" />
      <XAxis dataKey="name" />
      <YAxis />
    </LineChart>
  );

  return (
    <ResponsiveContainer width="100%" height={300}>
      {loading || error ? (
        <RectLoader width="100%" height="300" />
      ) : (
        <LineChart data={data} style={{ fontSize: 12 }}>
          <XAxis
            labelStyle={{ fontSize: 12 }}
            tickFormatter={(t) =>
              duration == 'day' ? dayjs(t).format('HH:mm') : dayjs(t).format('DD-MM-YY HH:mm')
            }
            dataKey="time"
            interval="preserveStartEnd"
            scale={data.map(({ time }) => time)}
          />
          <YAxis
            labelStyle={{ fontSize: 12 }}
            domain={['auto', 'auto']}
            dataKey="price"
            tickFormatter={(value) => `$${formatPrice(value)}`}
          />
          <Tooltip
            labelStyle={{ fontSize: 12 }}
            style={{ fontSize: 14 }}
            formatter={(value) => [formatPrice(value), `${symbol}/USD`]}
            labelFormatter={(value) => dayjs(value).format('MMM DD, HH:mm')}
          />
          <CartesianGrid stroke="#eee" strokeDasharray="4 4" />
          <Line type="monotone" dataKey="price" stroke="#222" />
        </LineChart>
      )}
    </ResponsiveContainer>
  );
};
